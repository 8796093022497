/*
  BACKGROUND FEATURE MODULES | věci na pozadí / "neviditelné vrstvy"

  Inicializace knihoven apod.
*/

// RECOMBEE | Inicializace
export const recombeeInit = async () => {
  const metaConfigElement: HTMLMetaElement | null = document.querySelector(
    'meta[name="CNCRecombeeInit"]'
  );

  if (metaConfigElement) {
    const { recombeeInit: init } = await import(
      /* webpackChunkName: 'default/chunk-recombee-init' */
      '@features/recombee/init'
    );

    init(metaConfigElement.getAttribute('content'));
  }
};

// MINILAZYLOAD | Lazy loading obrázků
export const cncMiniLazyLoad = async () => {
  const lazyLoadedImages: NodeListOf<HTMLImageElement> = document.querySelectorAll('.lazyload');

  if (lazyLoadedImages && lazyLoadedImages.length) {
    const { CncMiniLazyLoad } = await import(
      /* webpackChunkName: 'default/chunk-lazyload-images' */
      '@features/miniLazyLoad'
    );

    CncMiniLazyLoad({ lazyLoadedImages });
  }
};

// GTMHIGHLIGHT (dříve GTM2BORDERS) | Vykreslení rámečků pro GTM
export const gtmHighlight = async () => {
  const metaConfigElement: HTMLTemplateElement | null =
    document.querySelector('[data-gtm-highlight]');

  if (metaConfigElement) {
    const { GTMHighlight } = await import(
      /* webpackChunkName: 'default/chunk-gtm-highlight' */
      '@features/gtm/gtmHighlight'
    );

    GTMHighlight();
  }
};
