import { TrackerRing } from '@components';

import * as featuresArtObj from './featureCollections/featuresArtObj';
import * as featuresSystem from './featureCollections/featuresSystem';
import * as featuresBaseGUI from './featureCollections/featuresBaseGUI';
import * as features from './featureCollections/features';
import { isIOS } from '@utils/IOS';

import { SentryInit } from '@features/sentry';

SentryInit();

TrackerRing();

if (isIOS()) {
  document.body.classList.add('ios');
}

const importedModules = {
  ...featuresArtObj,
  ...featuresSystem,
  ...featuresBaseGUI,
  ...features,
};
const results = Object.values(importedModules).map((func) => func());

Promise.all(results).then(
  () => {
    // if (process.env.NODE_ENV === 'development') console.log('Všechny moduly načteny.');
  },
  () => {
    // if (process.env.NODE_ENV === 'development') console.log('Some modules not loaded');
  }
);
