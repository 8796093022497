// Vytvořit HTML strukturu placeholderu místo tlačítka 'Načíst další články'

import { stringToHTML } from '@utils/text';

interface PlaceholderLoadMoreProps {
  magazineName?: string;
  placeholderIcon?: string;
  placeholderTitle?: string;
  placeholderSubtitle?: string;
  elementClass?: string;
}

export const renderPlaceholderLoadMore = (
  magazineName: string,
  {
    placeholderIcon = '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none"><path d="M29.3337 6.90669L14.1203 22.1334L8.46699 16.48L10.347 14.6L14.1203 18.3734L27.4537 5.04002L29.3337 6.90669ZM26.387 13.6267C26.5603 14.3867 26.667 15.1867 26.667 16C26.667 21.8934 21.8937 26.6667 16.0003 26.6667C10.107 26.6667 5.33366 21.8934 5.33366 16C5.33366 10.1067 10.107 5.33335 16.0003 5.33335C18.107 5.33335 20.0537 5.94669 21.707 7.00002L23.627 5.08002C21.467 3.56002 18.8403 2.66669 16.0003 2.66669C8.64033 2.66669 2.66699 8.64002 2.66699 16C2.66699 23.36 8.64033 29.3334 16.0003 29.3334C23.3603 29.3334 29.3337 23.36 29.3337 16C29.3337 14.4134 29.0403 12.8934 28.5337 11.48L26.387 13.6267Z" fill="#388E3B"/></svg>',
    placeholderTitle = `Všechny příspěvky z&nbsp;${magazineName} máte již zobrazené.`,
    placeholderSubtitle = 'Vyberte si z&nbsp;nabídky nebo&nbsp;pokračujte na&nbsp;další články z&nbsp;jiných titulů.',
    elementClass = 'load-more-disabled',
  }: PlaceholderLoadMoreProps = {}
): HTMLElement | null => {
  return stringToHTML(`
    <div class="${elementClass}">
    <div class="${elementClass}__icon">
        ${placeholderIcon}
    </div>

    <div class="${elementClass}__text">
      <div class="${elementClass}__title">
        ${placeholderTitle}
      </div>
      <div class="${elementClass}__subtitle">
        ${placeholderSubtitle}
      </div>
    </div>
    </div>`);
};

export default renderPlaceholderLoadMore;
