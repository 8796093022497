/*
  FEATURE MODULES | samostatné funkční celky
*/

// GALERIE | Obrázky, videa, ...
export const gallery = async () => {
  const galleryElement: HTMLDivElement | null = document.querySelector('[data-gallery-init]');

  if (galleryElement) {
    const Gallery = await import(
      /* webpackChunkName: 'default/chunk-gallery' */
      '@features/gallery/Gallery'
    );

    Gallery.default();
  }
};

// ENQUIRY | Anketa
export const enquiry = async () => {
  const enquiryElements: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.enquiry-bars');

  if (enquiryElements && enquiryElements.length) {
    const { enquiryBars } = await import(
      /* webpackChunkName: 'default/chunk-enquiry' */
      '@box/article/EnquiryBars/enquiryBars'
    );

    enquiryBars(enquiryElements);
  }
};

// FÓRUM | Kompletní funkcionalita diskuzí
export const forum = async () => {
  const forumDiv: HTMLDivElement | null = document.querySelector('[data-forum]');

  if (forumDiv) {
    const Forum = await import(
      /* webpackChunkName: 'default/chunk-forum' */
      '@box/forum/Forum'
    );

    Forum.default();
  }
};

// RECOMBEE | Načítání + překreslování článků
export const recombeeArticles = async () => {
  const recombeeWrappers: NodeListOf<HTMLDivElement> = document.querySelectorAll('[data-recombee]');

  if (recombeeWrappers && recombeeWrappers.length) {
    const { Recombee } = await import(
      /* webpackChunkName: 'default/chunk-recombee' */
      '@box/listArticle/Recombee/Recombee'
    );

    Recombee(recombeeWrappers);
  }
};

// LIST-ARTICLE | Asynchronní donačítání článků
export const loadMore = async () => {
  const loadMoreElements: NodeListOf<HTMLLinkElement> =
    document.querySelectorAll('[data-load-more]');

  if (loadMoreElements && loadMoreElements.length) {
    const { LoadMore } = await import(
      /* webpackChunkName: 'default/chunk-load-more' */
      '@box/listArticle/LoadMore/LoadMore'
    );

    LoadMore(loadMoreElements);
  }
};

// LIST-ARTICLE | Odkryvání přednačtených doporučených článků
export const uncoverBehavArticles = async () => {
  const uncoverBehavArticlesBtn = document.querySelector<HTMLLinkElement>('.uncover-btn');

  if (uncoverBehavArticlesBtn) {
    const { UncoverBehavArticles } = await import(
      /* webpackChunkName: 'default/chunk-uncover-behav-articles' */
      '@box/listArticle/UncoverBehavArticles'
    );

    UncoverBehavArticles();
  }
};

// REKLAMA | HALFPAGE REPEATER MANAGER
export const halfPageManager = async () => {
  const topAside: HTMLElement | null = document.querySelector(
    '.main--first-content > aside:not(.no-repeaters)'
  );
  const bottomAside: HTMLElement | null = document.querySelector(
    '.main--second-content > aside:not(.no-repeaters)'
  );
  const footer: HTMLDivElement | null = document.querySelector('[data-footer-trigger-aside]');
  if ((topAside || bottomAside) && footer) {
    const HalfPageManager = await import(
      /* webpackChunkName: 'default/chunk-ads-repeater' */
      '@box/ads/HalfPageManager/HalfPageManager'
    );

    HalfPageManager.default();
  }
};

// NEWSLETTER
export const newsletter = async () => {
  const newsletters = document.querySelector<HTMLButtonElement>('.newsletters');

  if (newsletters) {
    const { Newsletter } = await import(
      /* webpackChunkName: 'default/newsletter' */
      '@box/newsletter/Newsletter'
    );

    Newsletter();
  }
};

// COMPANY SUBSCRIPTION
export const companySubscription = async () => {
  const CompanySubscriptionSelector = document.querySelector<HTMLElement>('.company-subscription');

  if (CompanySubscriptionSelector) {
    const { CompanySubscription } = await import(
      /* webpackChunkName: 'default/companySubscription' */
      '@box/companySubscription/companySubscription'
    );

    CompanySubscription();
  }
};

// SWIPER
export const swipers = async () => {
  const swiperSelectors = [...document.querySelectorAll('[data-swiper]')];

  if (swiperSelectors.length > 0) {
    const { Swiper } = await import(
      /* webpackChunkName: 'default/swiper' */
      '@features/swiper'
    );

    for (const swiperSelector of swiperSelectors) {
      if (swiperSelector instanceof HTMLDivElement) {
        Swiper(swiperSelector);
      }
    }
  }
};

// things article swiper
export const thingsArticleSwiper = async () => {
  const swiperThoughts: HTMLTemplateElement | null = document.querySelector('[data-thoughts]');

  if (swiperThoughts) {
    const { ThoughtsScroll } = await import(
      /* webpackChunkName: 'default/thingsArticleSwiper' */
      '@features/thingsArticleSwiper'
    );

    ThoughtsScroll(swiperThoughts);
  }
};

export const editorialPromo = async () => {
  const editorialPromoMagazines = ['reflex', 'e15', 'zeny'];
  const domain = window.location.hostname;

  if (editorialPromoMagazines.some((magazine) => domain.includes(magazine))) {
    const { EditorialPromo } = await import(
      /* webpackChunkName: 'default/chunk-editorial-promo' */
      '@features/editorialPromo/EditorialPromo'
    );

    EditorialPromo();
  }
};

export const tabsAndLinks = async () => {
  const tabsAndLinksSelectors = [...document.querySelectorAll('[data-tabs-and-links]')];

  if (tabsAndLinksSelectors.length > 0) {
    const { TabsAndLinks } = await import(
      /* webpackChunkName: 'default/tabs-and-links' */
      '@features/tabsAndLinks'
    );

    tabsAndLinksSelectors.forEach((tabsAndLinksSelector) => {
      if (tabsAndLinksSelector instanceof HTMLDivElement) {
        TabsAndLinks(tabsAndLinksSelector);
      }
    });
  }
};

export const topicBanner = async () => {
  const topicBannerElement: HTMLTemplateElement | null =
    document.querySelector('[data-topic-banner]');

  if (topicBannerElement) {
    const { TopicBanner } = await import(
      /* webpackChunkName: 'default/topic-banner' */
      '@features/topicBanner'
    );
    TopicBanner(topicBannerElement);
  }
};

// INSTAGRAM
export const instagramBox = async () => {
  const instagramBoxSelectors = [...document.querySelectorAll('[data-ig-box]')];

  if (instagramBoxSelectors.length > 0) {
    const { InstagramBox } = await import(
      /* webpackChunkName: 'default/instagram' */
      '@features/instagram/instagramBox'
    );

    for (const instagramBoxSelector of instagramBoxSelectors) {
      if (instagramBoxSelector instanceof HTMLElement) {
        await InstagramBox(instagramBoxSelector);
      }
    }
  }
};

// piano predplatne links
export const successReturnUrlAttacher = async () => {
  const searchUrlParams = new URLSearchParams(window.location.search);
  const successReturnUrl = searchUrlParams.get('success_return_url');
  if (!successReturnUrl) return;

  let linkTargets = [
    ...document.querySelectorAll('a.piano-landingpage__offer__button'),
  ] as Array<HTMLAnchorElement>;
  if (!linkTargets.length) return;

  linkTargets = linkTargets.splice(0, 2);

  const { SuccessReturnUrlAttacher } = await import(
    /* webpackChunkName: 'default/piano' */
    '@features/piano/index'
  );

  SuccessReturnUrlAttacher(successReturnUrl, linkTargets);
};

// Google One click Login Porta
export const portaInit = async () => {
  const userLogging: HTMLDivElement | null = document.querySelector('[data-user-not-logged]'); // Kontrolujeme, jestli uzivatel uz neni prihlaseny.
  const currentPageUrl = window.location.href; // Momentalne to mame omezeny, ze to budeme testovat na magazinu LideaZeme nebo Zeny.cz, pak tuto constantu vyhodit a podminku na povolene domény odebrat .
  if (
    userLogging &&
    (currentPageUrl.includes('lideazeme.cz') || currentPageUrl.includes('zeny.cz'))
  ) {
    const { PortaInit } = await import(
      /* webpackChunkName: 'default/portaInit' */
      '@features/porta/PortaInit'
    );

    await PortaInit();
  }
};

// JAKEBOX
export const jakeBox = async () => {
  const jakeBoxSelector = document.querySelector('[data-jakebox]');

  if (jakeBoxSelector) {
    const { JakeBox } = await import(
      /* webpackChunkName: 'default/jakebox' */
      '@features/jake/jakeBox'
    );

    if (jakeBoxSelector instanceof HTMLElement) {
      JakeBox(jakeBoxSelector);
    }
  }
};

// COLLAPSER
export const collapsers = async () => {
  const collapserSelectors: HTMLDivElement[] | [] = Array.from(
    document.querySelectorAll('[data-collapser]')
  );

  if (collapserSelectors.length > 0) {
    const { Collapser } = await import(
      /* webpackChunkName: 'default/collapser' */
      '@features/collapser'
    );

    for (const collapserSelector of collapserSelectors) {
      Collapser(collapserSelector);
    }
  }
};

// AUTHOR CARD LIST
export const authorCardList = async () => {
  const authorCardListSelector: HTMLDivElement | null =
    document.querySelector('[data-author-card-list]');

  if (authorCardListSelector) {
    const { AuthorCardList } = await import(
      /* webpackChunkName: 'default/author-card-list' */
      '@features/authorCardList'
    );

    AuthorCardList(authorCardListSelector);
  }
};
