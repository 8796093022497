import { GTMTypes } from '@customTypes/GTM';

interface GetTrackListItemProps {
  getString?: boolean;
  item: GTMTypes.TrackListItem;
}

export const getTrackListItem = ({
  getString = true,
  item,
}: GetTrackListItemProps): GTMTypes.TrackListItem | string => {
  const dataTrackList = {
    item,
  };
  return getString ? JSON.stringify(dataTrackList) : item;
};
