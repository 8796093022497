export const databaseId = 'czech-news-center-prod';

export const privateToken = '70U0WxdYzaEMPtAX0IfhiEhiMo6JcI1NtbHvH0nTn38eORv4PDkPTQ1ngsvCBuGf';

export const options = {
  baseUri: 'client-rapi-cnc.recombee.com',
};

// Získání všech elementů s atributem 'data-article-id' abychom je vyloučili z načtení dalších článků pro potlačení duplicit
const articleIdElements = document.querySelectorAll('[data-article-id]');
const articleIdsToExclude: Array<string | null> = Array.from(articleIdElements).map(
  (templateArticleId) => templateArticleId.getAttribute('data-article-id')
);

const filter = `'title' != null AND 'itemId' not in ["${articleIdsToExclude.join('", "')}"]`;

export const request: {
  returnProperties: boolean;
  distinctRecomms: boolean;
  includedProperties: string[];
  filter: string;
  scenario?: string;
} = {
  returnProperties: true,
  distinctRecomms: true, // Zamezit duplicity
  includedProperties: [
    'title',
    'url',
    'image',
    'published_at',
    'author',
    'categories',
    'locked',
    'is_premium',
    'updated_at',
    'domain',
    'main_category_name',
    'main_category_urlize',
    'magazine_name',
    'image_small',
    'image_medium',
    'image_large',
  ],
  filter: filter,
};
