// Vytvořit HTML strukturu tlačítko 'Načíst další články'

// TIP: vkládat pomocí https://developer.mozilla.org/en-US/docs/Web/API/Element/insertAdjacentElement

import { stringToHTML } from '@utils/text';
import { getNextPage } from '@utils/urlParam';

const error = (msg: string) => {
  return `<p data-load-more-error="rec" hidden="">${msg}</p>`;
};

interface LoadMoreButtonProps {
  scenario?: string;
  buttonText?: string;
  hasError?: boolean;
  errorMsg?: string;
  elementClass?: string;
}

export const renderButtonLoadMore = ({
  scenario,
  buttonText = 'Načíst další články',
  hasError = false,
  errorMsg = 'Něco se nepovedlo. Zkuste ještě jednou nebo zaktualizujte stránku.',
  elementClass = 'load-more',
}: LoadMoreButtonProps): HTMLElement | null => {
  const dataAtrrScenario = scenario ? `data-wrapper-loading-${scenario}` : '';

  return stringToHTML(`
    <div class="${elementClass}-wrapper" ${dataAtrrScenario} >
      ${hasError ? error(errorMsg) : ''}
     <a href="${getNextPage(
       window.location.href
     )}" rel="next" data-load-more-seo-wrapper  class="button button--contained button--lg ${elementClass}-btn" data-sidebar-recount>${buttonText}</a>
    </div>`);
};

export default renderButtonLoadMore;
