// Přesunutí reklamy článěk 'PR Standard' v seznamu článků

// Pozice se defaultně vypíše pod box, skript článek přemístí před tlačítko 'Načíst další'

import { movePRStandardArticleBeforeButtonLoadMore as move } from './movePRStandardArticleBeforeButtonLoadMore';

interface MovePRStandardProps {
  boxes?: Array<{ sourceSelector: string; targetSelector: string }>;
}

const movePRStandard = ({
  boxes = [
    // Box 'Doporučeno'
    {
      sourceSelector: '[data-pr-article-doporuceno]',
      targetSelector: '[data-wrapper-loading-doporucujeme]',
    },
    // Box 'Články odjinud'
    {
      sourceSelector: '[data-pr-article-odjinud]',
      targetSelector: '[data-wrapper-loading-odjinud]',
    },
  ],
}: MovePRStandardProps = {}): void => {
  // Pro všechny boxy
  boxes.forEach((box) => {
    // Přesunout PR článek před tlačítko 'Načíst další'
    const articlePRWrapper: HTMLElement | null = document.querySelector(box.sourceSelector);
    if (articlePRWrapper) move(box.targetSelector, articlePRWrapper);
  });
};

export default movePRStandard;
