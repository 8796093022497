import { CNCRecombee } from '@customTypes/recombee/CNCRecombee';

import breakpoints from '@breakpoints';

// Připravit DOM strukturu pro zdroj obrázku
const makeSource = (media: string, src: string) => `
    <source media="(${media})" srcset="${src}" />`;

/*
    Připravit zdroje obrázků pro <picture>

    @example
        const sources = getImageSources({ isRecombee: true, recombeeSrcSet });
        ${sources.join('')}

*/
interface GetImageSourcesProps {
  isRecombee?: boolean;
  recombeeSrcSet?: CNCRecombee.SRCset;
}

export const getImageSources = ({
  isRecombee = false,
  recombeeSrcSet,
}: GetImageSourcesProps): string[] => {
  const sources: string[] = [];

  // Recombee má specifický set obrázků
  if (isRecombee) {
    if (recombeeSrcSet) {
      Object.entries(recombeeSrcSet).forEach(([key, srcUrl]) => {
        let media;
        switch (key) {
          case 'small':
            media = breakpoints.mobile;
            break;
          case 'medium':
            media = breakpoints.tablet;
            break;
          case 'large':
            media = breakpoints.desktop;
            break;
          default:
            media = breakpoints.mobile;
        }
        if (srcUrl) sources.push(makeSource(media, srcUrl));
      });
    }
  }

  return sources;
};
