/*

  Zvýraznění elementů, které jsou trackovány pomocí GTM
  - spustí se při &gtmHighlight v URL (nebo postaru ?gtm2=borders)
  - interní vývojová záležitost

*/

import * as Sentry from '@sentry/browser';
import { GTMTypes } from '@customTypes/GTM';

export const GTMHighlight = () => {
  const gtmHighlightMetaConfig: HTMLTemplateElement | null =
    document.querySelector('[data-gtm-highlight]');
  if (!gtmHighlightMetaConfig) return;

  // 'scroll-to' elementy
  const scrollToElementsList = document.querySelectorAll('[data-track-scroll-to]');
  scrollToElementsList.forEach((element) => {
    const scrollToType = element.getAttribute('data-track-scroll-to');
    const elementParent: HTMLElement | null = element.parentElement;
    if (elementParent) {
      elementParent.classList.add('gtm-highlight__scroll-to');
      elementParent.innerText = scrollToType || '';
    }
  });

  // 'gallery' elementy
  const structureGallery = document.getElementsByClassName('article-structure-gallery-checker');
  Array.prototype.forEach.call(structureGallery, (element: HTMLElement) => {
    const parentNode: ParentNode | null = element.parentNode;
    if (parentNode && parentNode instanceof HTMLElement)
      parentNode.classList.add('gtm-highlight__gallery');
  });

  // 'track' elementy
  const trackElementsList = document.querySelectorAll(
    '[data-track-element-id], [data-track-list]:not([data-track-list="false"]), [data-track-scroll-to], [data-track-ad], [data-track-click]'
  );
  trackElementsList.forEach((element) => {
    element.classList.add('gtm-highlight__track');
  });

  // Ignorovat položky v rámci track elementů
  const disabledTrackElementsList = document.querySelectorAll('[data-track-list="false"]');
  disabledTrackElementsList.forEach((element) => {
    element.classList.add('gtm-highlight__track--disabled');
  });

  // Přidat dodatečné informace | elementId, position, etc.
  const additionalTrackedInfo: NodeListOf<HTMLElement> = document.querySelectorAll(
    '[data-track-list]:not([data-track-list="false"]'
  );
  additionalTrackedInfo.forEach((element: HTMLElement) => {
    try {
      const trackListConfig: string | null = element.getAttribute('data-track-list');
      const trackListConfigJSON = JSON.parse(trackListConfig || '') as {
        item: GTMTypes.TrackListItem;
      };
      if (!trackListConfig || !trackListConfigJSON)
        throw new Error('GTM HIGHLIGHT | additionalTrackedInfo no item');

      // Dobarvit na zeleno
      element.classList.add('gtm-highlight__track--green');

      // Informace položky
      const { elementId, position, source } = trackListConfigJSON.item;
      const sourceInfo = `<span class="gtm-highlight__track-source">${
        source ? source : 'no source'
      }</span>`;
      const trackedData = `<div class="gtm-highlight__track-additional-info">
        el.id: ${elementId || 'undefined'}
        <br />pos: ${position || 'undefined'}
        <br />${sourceInfo}
      </div>`;

      element.innerHTML = trackedData + element.innerHTML;
    } catch (err) {
      Sentry.captureException(err);
      // console.error('GTM 2.0 error element: ', element);
    }
  });
};

export default GTMHighlight;
