/*
  ARTOBJ MODULES
*/

// ZÁMEK ZA PŘIHLÁŠENÍ
export const htmlBoxRegWall = async () => {
  const boxes: NodeListOf<HTMLDivElement> | null = document.querySelectorAll(
    '[data-html-box-reg-wall]'
  );

  if (boxes && boxes.length > 0) {
    const HtmlBoxRegWall = await import(
      /* webpackChunkName: 'default/chunk-html-box-reg-wall' */
      '@artobj/HtmlBoxRegWall'
    );

    HtmlBoxRegWall.default(boxes);
  }
};
