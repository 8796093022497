// Ořízne text na určitý počet znaků.
export function maintainTextLength(text: string, finalLength: number): string {
  // Pokud je text delší
  if (text && text.length > finalLength) {
    let croppedText = text.replace(/(<([^>]+)>)/gi, '');

    // Rozlišit zda obsahuje mezery
    if (text.includes(' ')) {
      croppedText = croppedText
        .slice(0, finalLength + 1)
        .split(' ')
        .slice(0, -1)
        .join(' ');
    } else {
      croppedText = croppedText.slice(0, finalLength);
    }

    // Vrátit upravený řetězec a přidat '...'
    return `${croppedText}…`;
  }

  // Vrátit výchozí text
  return text;
}

// Přidá úvodní nulu pro dvojciferné číslo.
export function pad(n: number): string {
  return n < 10 ? `0${n.toString()}` : n.toString();
}

/*
  Pole s překlady měsíců

  example:
    const publishedAt = new Date(currentValues.published_at * 1000); // V milisekundách.
    monthsTranslates[publishedAt.getMonth()]
*/
export const monthsTranslates = [
  'ledna',
  'února',
  'března',
  'dubna',
  'května',
  'června',
  'července',
  'srpna',
  'září',
  'října',
  'listopadu',
  'prosince',
];

// Převod stringu (například s pomocí template literals) na DOM
export const stringToHTML = (content: string): HTMLElement | null => {
  if (!content) return null;

  const template = document.createElement('template');
  template.innerHTML = content;

  return template.content.firstElementChild?.cloneNode(true) as HTMLElement;
};
