// Získat hodnotu parametru z URL
export const getUrlParamValue = (url: string, param: string, defaultValue?: number | string) => {
  const results = new RegExp(`[?&]${param}=([^&#]*)`).exec(url);

  if (results) {
    return results[1];
  }

  return defaultValue;
};

// Získat hodnotu parametru z URL jako číslo
export const getUrlParamNumberValue = (url: string, param: string, defaultValue: number = 0) => {
  const results = getUrlParamValue(url, param);

  if (results) {
    return +results;
  }

  return defaultValue;
};

export const getNextPage = (url: string) => {
  const currentPage = getUrlParamNumberValue(url, 'page', 0);
  const nextPage = currentPage + 1;
  const pageParam = currentPage === 0 ? 'page=2' : `page=${nextPage}`;
  const newUrl = url.includes('page=')
    ? url.replace(/page=\d+/, pageParam)
    : url + (url.includes('?') ? '&' : '?') + pageParam;
  return newUrl;
};

export const removeDuplicateParams = (url: string) => {
  const urlObject = new URL(url);

  const params = new URLSearchParams(urlObject.search);
  const uniqueParams = new Set(params.keys());

  const newSearch = [...uniqueParams].map((key) => `${key}=${params.get(key)}`).join('&');
  const newUrl = `${urlObject.origin}${urlObject.pathname}?${newSearch}`;
  return newUrl;
};

// Aktualizovat hodnotu parametru v URL
export const updateParamInUrl = (param: string, value: string) => {
  const url = new URL(window.location.href);
  url.searchParams.set(param, value);
  window.history.pushState({}, '', url.toString());
};
