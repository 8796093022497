import * as Sentry from '@sentry/browser';
import { Vendors } from '@customTypes/Didomi';

declare global {
  export interface Window {
    ringTr?: string;
    tst?: string;
  }
}

const areRequiredConsentsEnabled = (consentList: Vendors, values: string[]): boolean => {
  return values.every(
    (item) =>
      Object.prototype.hasOwnProperty.call(consentList, item) && consentList[item].enabled === true
  );
};

export const isRequiredUserConsent = async (): Promise<boolean> => {
  const requiredVendorsForConsent = ['czechnews-nJYjk3Bf'];
  const requiredPurposesForConsent = ['cookies', 'publishers-Kqq3iPge'];
  const userStatus = await window.Didomi.getCurrentUserStatus();

  const vendorsEnabled = areRequiredConsentsEnabled(userStatus.vendors, requiredVendorsForConsent);

  const purposesEnabled = areRequiredConsentsEnabled(
    userStatus.purposes,
    requiredPurposesForConsent
  );

  return vendorsEnabled && purposesEnabled;
};

export const trackerRing = () => {
  try {
    // window.ringTr is unique tracking code for magazine template: def-footer-measuring-codes.twig
    if (window.ringTr) {
      window.didomiOnReady = window.didomiOnReady || [];
      window.didomiOnReady.push(() => {
        void (async () => {
          const allConsentsFullfiled = await isRequiredUserConsent();

          if (allConsentsFullfiled) {
            const cncHost = 'https://stat.cncenter.cz';

            // window.tst is unix timestamp for tracking template: def-footer-measuring-codes.twig
            const cncTst: string | number =
              typeof window.tst === 'undefined' ? new Date().getTime() : window.tst;

            const url: string = `${cncHost}/tr/${window.ringTr}?tst=${cncTst}`;
            const img = new Image();
            img.style.setProperty('position', 'absolute');
            img.style.setProperty('top', '-9999px');
            img.src = url;
            document.body.appendChild(img);
          }
        })();
      });
    }
  } catch (err) {
    Sentry.captureException(err);
    throw Error('Error with tracker.');
  }
};

export default trackerRing;
