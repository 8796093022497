// Trackování chyb
// Dashboard projektu: https://sentry.cnci.tech/organizations/cnc/projects/javascript-ring/?project=16
// Dokumentace: https://docs.sentry.io/platforms/javascript/

import * as Sentry from '@sentry/browser';
// import dsnList from './dsnList.json';
import ignoreErrors from './ignoreErrors.json';

export const SentryInit = () => {
  const runOnLocalhost = false; // Zapnout lokálně dle potřeby, POZOR: neposílat do GITu

  const actualHost = window.location.host;
  const isLocalhost = actualHost.includes('local.cnci.cz');

  const dsnList = [
    {
      host: 'www.lideazeme.cz',
      dsn: 'https://7584daa60cb779386c70ddd8e79c7b5f@sentry.cnci.tech/16',
      env: 'prod',
    },
    {
      host: 'lideazeme.master.frontend.stage.cnci.tech',
      dsn: 'https://7584daa60cb779386c70ddd8e79c7b5f@sentry.cnci.tech/16',
      env: 'stage',
    },
    {
      host: 'lideazeme.local.cnci.cz',
      dsn: 'https://7584daa60cb779386c70ddd8e79c7b5f@sentry.cnci.tech/16',
      env: 'dev',
    },
  ];
  const dsn = dsnList.find(({ host }) => host.includes(actualHost));

  if (isLocalhost && !runOnLocalhost) {
    return;
  }

  if (dsn) {
    Sentry.init({
      dsn: dsn.dsn,

      integrations: [Sentry.browserTracingIntegration()],

      release: 'uniweb-fe-bundle@1.0.0',

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.2,

      environment: dsn.env,

      debug: isLocalhost,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,

      ignoreErrors,

      denyUrls: [
        'graph.facebook.com',
        'connect.facebook.net',
        'tarantula.gjirafa.net',
        'gjirafa.net',
        'caroda.io',
        'apple.com',
        'jsdelivr.net',
        'onthe.io',
        'bisko.gjirafa.tech',
        'tt.onthe.io',
        'performax.cz',
        'google-analytics.com',
        'static.criteo.net',
        'cdn.doubleverify.com',
        'cnc-ads-controller',
        'dv-measurements',
        'doubleclick.net',
        'adform.net',
      ],
    });
  }
};
