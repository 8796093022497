import * as RecombeeJSAPIClient from 'recombee-js-api-client';

import * as recombeeConf from '@utils/recombee';

// Nastavit API klienta
export const recombeeClient = new RecombeeJSAPIClient.ApiClient(
  recombeeConf.databaseId,
  recombeeConf.privateToken,
  recombeeConf.options
);

export default recombeeClient;
