export const movePRStandardArticleBeforeButtonLoadMore = (
  targetSelector: string,
  prArticleWrapper: HTMLElement
) => {
  const prArticleHTML: string | null = prArticleWrapper.innerHTML;
  const htmlComment: string = '<!-- Reklamní pozice PR Standard -->';

  const loadMoreWrapper: HTMLElement | null = document.querySelector(`${targetSelector}`);
  if (loadMoreWrapper) {
    loadMoreWrapper.insertAdjacentHTML('beforebegin', `${htmlComment} ${prArticleHTML}`);
    prArticleWrapper.remove();
  }
};
