export class GoogleTagManager {
  send(pageNumber: number, linkText: string = ''): void {
    /* GTM 2.0 - dataLayer push "page" */
    if (window.didomiOnReady !== undefined) {
      window.didomiOnReady.push(function didomiCallback() {
        if (Array.isArray(window.dataLayer)) {
          window.dataLayer.push({
            event: 'page_next',
            page: {
              pagination: { page: pageNumber },
              _clear: true,
            },
            ...(linkText && {
              link: {
                text: linkText,
              },
            }),
          });
        }
      });
    }
  }

  track(): void {
    /* eslint-disable */
    if (window.gtmService && window.gtmService.observe instanceof Function) {
      // @ts-ignore TODO: zjisit zda nemáme do observe() posílat nějaký parametr
      window.gtmService.observe();
    }
    /* eslint-enable */
  }
}

export default GoogleTagManager;
