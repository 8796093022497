// Komponenta umožňuje načtení SVG souboru pro další práci

import * as Sentry from '@sentry/browser';
import { loadSvgFile, LoadSvgFileProps } from '@utils/svg';

type ImageDimensions = {
  width: number;
  height: number;
};

interface SVGImageProps extends LoadSvgFileProps {
  size?: number | ImageDimensions;
  className?: string;
  targetElement: HTMLElement;
  prepend?: boolean;
}

// Custom type predicate to check if size is number or ImageDimensions
export const hasDifferentImageDimensions = (
  size: number | ImageDimensions
): size is ImageDimensions => {
  return (
    (size as ImageDimensions).width !== undefined && (size as ImageDimensions).height !== undefined
  );
};

// Připravit rozměry obrázku
export const prepareSizes = (
  size: number | ImageDimensions = { width: 24, height: 24 }
): { width: number; height: number } => {
  const width = hasDifferentImageDimensions(size) ? size.width : size;
  const height = hasDifferentImageDimensions(size) ? size.height : size;

  return { width, height };
};

const SVGImage = async ({
  code,
  title,
  description,
  magazine = 'default',
  size = { width: 24, height: 24 },
  className,
  targetElement,
  prepend = false,
}: SVGImageProps) => {
  // Připravit obrázek
  await loadSvgFile
    .load({
      code,
      magazine,
      title,
      description,
    })
    .then((svgElement: SVGSVGElement) => {
      if (!svgElement) return;
      // Připravit rozměry
      const preparedSizes = prepareSizes(size);

      // Nastavit rozměry
      svgElement.setAttribute('width', preparedSizes.width.toString());
      svgElement.setAttribute('height', preparedSizes.height.toString());

      // Přidat class
      if (className) svgElement.setAttribute('class', className);

      // Zařadit do DOMu
      if (prepend) targetElement.prepend(svgElement);
      else targetElement.append(svgElement);
    })
    .catch((err) => {
      Sentry.captureException(err);
      // TODO: Zakomentováno kvůli unit testu (FlashMessage.test.ts)
      // throw new Error(`SVG ${code} se nepodařilo přidat.`);
    });
};

export default SVGImage;
